import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import ClassList from "./components/ClassList";
import ClassDetails from "./components/ClassDetails";
import RaceList from "./components/RaceList";
import RaceDetails from "./components/RaceDetails";
import MonsterList from "./components/MonsterList";
import MonsterDetails from "./components/MonsterDetails";
import Header from "./components/Header";
import "./App.css";

const App = () => {
  const [classes, setClasses] = useState([]);

  const [races, setRaces] = useState([]);

  const [monsters, setMonsters] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      const result = await axios("https://www.dnd5eapi.co/api/classes");
      setClasses(result.data.results);
    };
    fetchClasses();

    const fetchRaces = async () => {
      const result = await axios("https://www.dnd5eapi.co/api/races");
      setRaces(result.data.results);
    };
    fetchRaces();

    const fetchMonsters = async () => {
      const result = await axios("https://www.dnd5eapi.co/api/monsters");
      setMonsters(result.data.results);
    };
    fetchMonsters();
  }, []);
  //<Router basename="/app">
  return (
    <Router basename="/app">
      <div className="container">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <RaceList races={races} />
                <ClassList classes={classes} />
                <MonsterList monsters={monsters} />
              </>
            }
          />
          <Route path="/races/:raceId" element={<RaceDetails />} />
          <Route path="/classes/:classId" element={<ClassDetails />} />
          <Route path="/monsters/:monsterId" element={<MonsterDetails />} />
          {/* Add routes for ClassDetails and MonsterDetails similarly */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
