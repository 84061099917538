import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../Spinner";
const MonsterDetails = () => {
  const [monsterDetails, setMonsterDetails] = useState(null);
  const { monsterId } = useParams();
  const apiBaseUrl = "https://www.dnd5eapi.co";

  useEffect(() => {
    const fetchMonsterDetails = async () => {
      const result = await axios(
        `https://www.dnd5eapi.co/api/monsters/${monsterId}`
      );
      setMonsterDetails(result.data);
      console.log(result.data);
    };

    fetchMonsterDetails();
  }, [monsterId]);

  if (!monsterDetails) return <Spinner />;

  return (
    <div className="monster-details details">
      <h3>{monsterDetails.name}</h3>
      <p>
        <em>{monsterDetails.desc}</em>
      </p>
      <ul className="stats">
        <li>
          <strong>STR:</strong>
          {monsterDetails.strength}
        </li>
        <li>
          <strong>DEX:</strong>
          {monsterDetails.dexterity}
        </li>
        <li>
          <strong>CON:</strong>
          {monsterDetails.constitution}
        </li>
        <li>
          <strong>INT:</strong>
          {monsterDetails.intelligence}
        </li>
        <li>
          <strong>WIS:</strong>
          {monsterDetails.wisdom}
        </li>
        <li>
          <strong>CHA:</strong>
          {monsterDetails.charisma}
        </li>
      </ul>
      <h4>HD: {monsterDetails.hit_dice}</h4>
      <h4>HP: {monsterDetails.hit_points}</h4>
      <h3>Armor Class</h3>
      {monsterDetails.armor_class.map((ac, index) => (
        <div key={index} className="armor-class">
          <p>Type: {ac.type}</p>
          <p>Value: {ac.value}</p>
          {ac.armor && (
            <ul>
              {ac.armor.map((armorItem) => (
                <li key={armorItem.index}>
                  Armor: {armorItem.name}
                  {/* If you also want to link to the armor's URL */}
                  {/* <a href={`https://www.dnd5eapi.co${armorItem.url}`}>{armorItem.name}</a> */}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
      <h4>XP: {monsterDetails.xp}</h4>
      {monsterDetails.image && (
        <div className="monster-image">
          <img
            src={apiBaseUrl + monsterDetails.image}
            alt={monsterDetails.name}
          />
        </div>
      )}
      {/* Add any additional details you want to display here */}
    </div>
  );
};

export default MonsterDetails;
