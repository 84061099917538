import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import Spinner from "../Spinner";

const ClassDetails = () => {
  const [classDetails, setClassDetails] = useState(null);
  const { classId } = useParams();
  useEffect(() => {
    const fetchClassDetails = async () => {
      const result = await axios(
        `https://www.dnd5eapi.co/api/classes/${classId}`
      );
      setClassDetails(result.data);
      console.log(result.data);
    };

    fetchClassDetails();
  }, [classId]);

  if (!classDetails) return <Spinner />;

  return (
    <div className="details class-details">
      {/* Render class details */}
      <h3>{classDetails.name}</h3>
      <ul>
        <li>
          <strong>Hit Dice:</strong> d{classDetails.hit_die}
        </li>
      </ul>
      {classDetails.proficiencies && (
        <>
          <h3>Proficiencies:</h3>
          <ul>
            {classDetails.proficiencies.map((proficiency) => (
              <li key={proficiency.index}>{proficiency.name}</li>
            ))}
          </ul>
        </>
      )}
      {classDetails.saving_throws && (
        <>
          <h4>Saving Throws:</h4>
          <ul>
            {classDetails.saving_throws.map((save) => (
              <li key={save.index}>{save.name}</li>
            ))}
          </ul>
        </>
      )}
      {classDetails.subclasses && (
        <>
          <h4>Sub Classes:</h4>
          <ul>
            {classDetails.subclasses.map((sc) => (
              <li key={sc.index}>{sc.name}</li>
            ))}
          </ul>
        </>
      )}
      {/* Add any additional details you want to display here */}
    </div>
  );
};

export default ClassDetails;
