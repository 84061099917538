import { Link } from "react-router-dom";

const RaceList = ({ races }) => {
  return (
    <>
      <h2>Races</h2>
      <ul className="list race-list">
        {races.map((race) => (
          <li key={race.index} className="item race-item">
            <Link to={`/races/${race.index}`}>{race.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default RaceList;
