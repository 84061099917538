import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Check if the current location is not the home page
  const notHomePage = location.pathname !== "/";
  return (
    <header>
      {notHomePage && <button onClick={() => navigate("/")}>Back</button>}
      <h1>D&D 5E Reference</h1>
    </header>
  );
};

export default Header;
