import { Link } from "react-router-dom";

const MonsterList = ({ monsters }) => {
  return (
    <>
      <h2>Monsters</h2>
      <ul className="list monster-list">
        {monsters.map((monster) => (
          <li key={monster.index} className="item monster-item">
            <Link to={`/monsters/${monster.index}`}>{monster.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MonsterList;
