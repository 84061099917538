import { Link } from "react-router-dom";

const ClassList = ({ classes }) => {
  return (
    <>
      <h2>Classes</h2>
      <ul className="list class-list">
        {classes.map((cls) => (
          <li key={cls.index} className="item class-item">
            <Link to={`/classes/${cls.index}`}>{cls.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ClassList;
