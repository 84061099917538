import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../Spinner";
const RaceDetails = () => {
  const [raceDetails, setRaceDetails] = useState(null);
  const { raceId } = useParams(); // extract the race ID from the URL

  useEffect(() => {
    const fetchRaceDetails = async () => {
      // Replace `raceUrl` with the URL structured using `raceId`
      const result = await axios(`https://www.dnd5eapi.co/api/races/${raceId}`);
      setRaceDetails(result.data);
      console.log(result.data);
    };

    fetchRaceDetails();
  }, [raceId]);

  if (!raceDetails) return <Spinner />;

  return (
    <div className="details race-details">
      <h3>{raceDetails.name}</h3>
      <p>
        <strong>Speed:</strong> {raceDetails.speed}
      </p>
      <p>
        <strong>Age:</strong> {raceDetails.age}
      </p>
      <p>
        <strong>Alignment:</strong> {raceDetails.alignment}
      </p>
      <p>
        <strong>Size:</strong> {raceDetails.size}
        <em>{raceDetails.size_description}</em>
      </p>
      <p>
        <strong>Languages:</strong> {raceDetails.language_desc}
      </p>
      {raceDetails.ability_bonuses && (
        <>
          <h4>Ability Bonuses:</h4>
          <ul>
            {raceDetails.ability_bonuses.map((bonus, index) => (
              <li key={index}>
                <strong>{bonus.ability_score.name}:</strong> +{bonus.bonus}
              </li>
            ))}
          </ul>
        </>
      )}
      {raceDetails.traits && (
        <>
          <h3>Traits:</h3>
          <ul>
            {raceDetails.traits.map((trait, index) => (
              <li key={index}>{trait.name}</li>
            ))}
          </ul>
        </>
      )}
      {/* Add any additional details you want to display here */}
    </div>
  );
};

export default RaceDetails;
